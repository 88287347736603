import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import "../styles/body/navbar.css";
import config from "../config";
import UpperFormatt from "../components/UpperFormatt"
import LocalStorageCleaner from "../scripts/LocalStorageCleaner";
import UpdaterCounter from "../services/updateCounter";

const domain = config.api_domain.domain

const VehicleList = ({ selectedType, handleSelectType }) => {
  const [vehicleTypes, setVehicleTypes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const region = localStorage.getItem(`region`)
      const response = await axios.get(
        `${domain}/electric_vehicles/${region}`,
      );
      const vehiclesData = response.data.body;

      const vehicles = vehiclesData.filter(item  => item.state === 'active')
      const types = {};
      for (let i = 0; i < vehicles.length; i++) {
        const vehicle = vehicles[i];
        if (!types[vehicle.type]) {
          types[vehicle.type] = [vehicle];
        } else {
          types[vehicle.type].push(vehicle);
        }
      }

      setVehicleTypes(types);
    };

    fetchData();
  }, []);

  const HandleCounter = (id, name, id_country, url) => {
    LocalStorageCleaner()
    UpdaterCounter(id, name, id_country)
    window.location.href = url;
  }

  return (
    <ul
      className="d-flex flex-wrap align-items-center justify-content-between text-white"
      style={{ minHeight: "100%" }}
    >
      {Object.keys(vehicleTypes).map((type) => (
        <li
          className={`nav-item dropdown ${
            selectedType === type ? "active" : ""
          }`}
          key={type}
          onClick={() => handleSelectType(type)}
        >
          <a
            href="#"
            className="nav-link dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
             <UpperFormatt string={type}/>
          </a>
          <ul className="dropdown-menu shadow-left">
            {vehicleTypes[type].map((vehicle) => {
              const url = `/home/${vehicle.id}/${vehicle.type}/subsystems/features/${vehicle.id}`;
              return (
                <li key={vehicle.id} className="sub-category">
                  <Link
                    to={url}
                    className="dropdown-item"
                    onClick={(e) => {
                      e.preventDefault();
                      HandleCounter(vehicle.id, vehicle.name, vehicle.id_country, url);
                    }}
                  >
                    {vehicle.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </li>
      ))}
    </ul>
  );
};

export default VehicleList;
