import React, { useState } from "react";
import NumberFormatter from "../../../components/Formarts";
import BarChart from "../../../components/charts/BarChart";
import "../../../styles/contens.css";
import TooltipModal from "../../../components/modals/ToolTipModal";

const TableFinancial = ({ data }) => {

  const [showElectricTable, setShowElectricTable] = useState(false);
  const [showFuelTable, setShowFuelTable] = useState(false);

  let totalSum = 0;
  let totalSumInterest = 0;
  let totalSumFuel = 0;
  let totalSumInterestFuel = 0;

  if (!data || data.length <= 0 || data.error || data === null) {
    return <p className="subtitle">Sin parámetros asignados</p>;
  }

  let full_price
  let full_fuel_price
  let totals

  if (data[0].loan === "false") {
    full_price = data[0].price
    full_fuel_price = data[0].fuel_price

    totals = {
      electric: full_price,
      fuel: full_fuel_price
    }

    localStorage.setItem("total_payed", JSON.stringify(totals));
    return <div className="subtitle">
      <div className="father-box">
        <div className="child-box shadow-left">
          <div className="title">
            <h4 className='subtitle'>COSTO DEL VEHÍCULO ELÉCTRICO</h4>
            <br />
            {data.length > 0 && (
              <>
                <p className="big-result-text">$<NumberFormatter number={data[0].price}/> </p>
              </>
            )}
          </div>
        </div>

        <div className="child-box shadow-left">
          <div className="title">
            <h4 className='subtitle'>COSTO DEL VEHÍCULO A COMBUSTION</h4>
            <br />
            {data.length > 0 && (
              <>
                <p className="big-result-text">$<NumberFormatter number= {data[0].fuel_price}/> </p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="father-box">
      <div className="child-box shadow-left">
              <BarChart data={{
                categories: ['COMPRA'],
                series: [
                  {
                    name: 'Eléctrico',
                    data: [parseFloat(data[0].price)],
                  },
                  {
                    name: 'Combustión',
                    data: [parseFloat(data[0].fuel_price)],
                  }
                ],
              }} />
            </div>
      </div>

    </div>;
  }

  else {

    full_price = data[0].price * (1 - (data[0].financing / 100))
    full_fuel_price = data[0].fuel_price * (1 - (data[0].financing / 100))

    data[0].totalPayed.forEach((datas) => {
      totalSum += datas["data"];
    });

    data[0].totalInterest.forEach((datas) => {
      totalSumInterest += datas["data"];
    });

    data[0].totalInterestFuel.forEach((datas) => {
      totalSumInterestFuel += datas["data"];
    });

    data[0].totalPayedFuel.forEach((datas) => {
      totalSumFuel += datas["data"];
    });

    totals = {
      electric: totalSum + full_price,
      fuel: totalSumFuel + full_fuel_price
    }

    localStorage.setItem("total_payed", JSON.stringify(totals));
  }

  return (
    <div>
      {data.length > 0 && (
        <>
          <div className="father-box">

            <div className="child-box shadow-left">
              <div className="title">
                <h4 className='subtitle'>VEHÍCULO ELÉCTRICO </h4>
                <br />
                <h5>TABLA DE AMORTIZACIÓN <TooltipModal text={'Proyección mes a mes del valor financiado para la compra del vehículo, con detalle de intereses pagados y abono a capital en cada una de las cuotas mensuales'} title={'Tabla de amortización'} /></h5>
                <br />
              </div>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td className="table-head col-1">Mes</td>
                    <td className="table-head">Cuota Fija</td>
                    <td className="table-head">Abono a capital</td>
                    <td className="table-head">Intereses</td>
                    <td className="table-head">Saldo Final</td>
                  </tr>
                  {data[0].amortizationTable.slice(0, showElectricTable ? undefined : 5).map((datas, index) => (
                    <tr key={index}>
                      <td data-label="Mes" className="col-1">{datas["mes"]}</td>
                      <td data-label="Cuota Fija">
                        $ <NumberFormatter number={datas["Pago"]} />
                      </td>
                      <td data-label="Abono a capital">
                        $ <NumberFormatter number={datas["AbonoCapital"]} />
                      </td>
                      <td data-label="Intereses">
                        $ <NumberFormatter number={datas["Intereses"]} />
                      </td>
                      <td data-label="Saldo Final">
                        $ <NumberFormatter number={datas["saldoFinal"]} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button onClick={() => setShowElectricTable(!showElectricTable)}>
                {showElectricTable ? 'Ver menos' : 'Ver más'}
              </button>
              <hr />
              <div className="father-box">
                <div className="child-sm-box">
                  <h5>TOTAL INTERESES PAGADOS <TooltipModal text={'Valor total de intereses pagados durante la amortización de la deuda'} title={'Total de intereses pagados'} /></h5>
                  <br />
                  <table className="table table-sm">
                    <tbody>
                      <tr>
                        <td className="table-head col-2">Año</td>
                        <td className="table-head">Costo anual <TooltipModal text={'Valor proyectado para cada uno de los años en ejecución'} title={'Costo anual'} /></td>
                        <td className="table-head">Valor presente <TooltipModal text={'Corresponde al Valor Presente Neto de cada uno de los años en ejecución, el cual permite establecer un costo total a día de hoy en una proyección financiera'} title={'Valor presente'} /> </td>
                      </tr>
                      {data[0].totalInterest.map((datas, index) => (
                        <tr key={index}>
                          <td>
                            <b>{index + 1}</b>
                          </td>
                          <td>
                            $ <NumberFormatter number={datas["fixedFee"]} />
                          </td>
                          <td>
                            $ <NumberFormatter number={datas["data"]} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <h4 className="big-result-text">$ <NumberFormatter number={totalSumInterest} /></h4>
                </div>
                <div className="child-sm-box">
                  <h5>TOTAL PAGADO <TooltipModal text={'Valor total pagado durante la amortización de la deuda. Comprende la suma de intereses pagados y el abono a capital'} title={'Total Pagado'} /></h5>
                  <br />
                  <table className="table table-sm">
                    <tbody>
                      <tr>
                        <td className="table-head col-2">Año</td>
                        <td className="table-head">Costo anual <TooltipModal text={'Valor proyectado para cada uno de los años en ejecución'} title={'Costo anual'} /></td>
                        <td className="table-head">Valor presente <TooltipModal text={'Corresponde al Valor Presente Neto de cada uno de los años en ejecución, el cual permite establecer un costo total a día de hoy en una proyección financiera'} title={'Valor presente'} /></td>
                      </tr>
                      {data[0].totalPayed.map((datas, index) => (
                        <tr key={index}>
                          <td>
                            <b>{index + 1}</b>
                          </td>
                          <td>
                            $ <NumberFormatter number={datas["fixedFee"]} />
                          </td>
                          <td>
                            $ <NumberFormatter number={datas["data"]} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <h4 className="big-result-text">$ <NumberFormatter number={totalSum + full_price} /></h4>
                  <p>*{data[0].financing}% financiado </p>
                </div>
              </div>
            </div>

            <div className="child-box shadow-left">
              <div className="title">
                <h4 className="subtitle">VEHÍCULO A COMBUSTIÓN</h4>
                <br />
                <h5>TABLA DE AMORTIZACIÓN <TooltipModal text={'Proyección mes a mes del valor financiado para la compra del vehículo, con detalle de intereses pagados y abono a capital en cada una de las cuotas mensuales'} title={'Tabla de amortización'} /></h5>
                <br />
              </div>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td className="table-head col-1">Mes</td>
                    <td className="table-head">Cuota Fija</td>
                    <td className="table-head">Abono a capital</td>
                    <td className="table-head">Intereses</td>
                    <td className="table-head">Saldo Final</td>
                  </tr>
                  {data[0].amortizationTableFuel.slice(0, showFuelTable ? undefined : 5).map((datas, index) => (
                    <tr key={index}>
                      <td data-label="Mes" className="col-1">{datas["mes"]}</td>
                      <td data-label="Cuota fija">
                        $ <NumberFormatter number={datas["Pago"]} />
                      </td>
                      <td data-label="Abono capital">
                        $ <NumberFormatter number={datas["AbonoCapital"]} />
                      </td>
                      <td data-label="Intereses">
                        $ <NumberFormatter number={datas["Intereses"]} />
                      </td>
                      <td data-label="Saldo final">
                        $ <NumberFormatter number={datas["saldoFinal"]} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button onClick={() => setShowFuelTable(!showFuelTable)}>
                {showFuelTable ? 'Ver menos' : 'Ver más'}
              </button>
              <hr />
              <div className="father-box">
                <div className="child-sm-box">
                  <h5>TOTAL INTERESES PAGADOS <TooltipModal text={'Valor total de intereses pagados durante la amortización de la deuda'} title={'Total de intereses pagados'} /></h5>
                  <br />
                  <table className="table table-sm">
                    <tbody>
                      <tr>
                        <td className="table-head col-2">Año</td>
                        <td className="table-head">Costo anual <TooltipModal text={'Valor proyectado para cada uno de los años en ejecución'} title={'Costo anual'} /></td>
                        <td className="table-head">Valor presente <TooltipModal text={'Corresponde al Valor Presente Neto de cada uno de los años en ejecución, el cual permite establecer un costo total a día de hoy en una proyección financiera'} title={'Valor presente'} /></td>
                      </tr>
                      {data[0].totalInterestFuel.map((datas, index) => (
                        <tr key={index}>
                          <td>
                            <b>{index + 1}</b>
                          </td>
                          <td>
                            $ <NumberFormatter number={datas["fixedFee"]} />
                          </td>
                          <td>
                            $ <NumberFormatter number={datas["data"]} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <h4 className="big-result-text">$ <NumberFormatter number={totalSumInterestFuel} /></h4>
                </div>
                <div className="child-sm-box">
                  <h5>TOTAL PAGADO <TooltipModal text={'Valor total pagado durante la amortización de la deuda. Comprende la suma de intereses pagados y el abono a capital'} title={'Total pagado'} /></h5>
                  <br />
                  <table className="table table-sm">
                    <tbody>
                      <tr>
                        <td className="table-head col-2">Año</td>
                        <td className="table-head">Costo anual <TooltipModal text={'Valor proyectado para cada uno de los años en ejecución'} title={'Costo anual'} /></td>
                        <td className="table-head">Valor presente <TooltipModal text={'Corresponde al Valor Presente Neto de cada uno de los años en ejecución, el cual permite establecer un costo total a día de hoy en una proyección financiera'} title={'Valor presente'} /></td>
                      </tr>
                      {data[0].totalPayedFuel.map((datas, index) => (
                        <tr key={index}>
                          <td>
                            <b>{index + 1}</b>
                          </td>
                          <td>
                            $ <NumberFormatter number={datas["fixedFee"]} />
                          </td>
                          <td>
                            $ <NumberFormatter number={datas["data"]} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <h4 className="big-result-text">$ <NumberFormatter number={totalSumFuel + full_fuel_price} /></h4>
                  <p>*{data[0].financing}% financiado </p>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="charts">
            <div className="chart shadow-left">
              <BarChart name={'Total en consumibles'} data={{
                categories: ['Intereses Pagados', 'Total Pagado'],
                series: [
                  {
                    name: 'Eléctrico',
                    data: [data[0].totalInterest[0].data, data[0].totalPayed[0].data],
                  },
                  {
                    name: 'Combustión',
                    data: [data[0].totalInterestFuel[0].data, data[0].totalPayedFuel[0].data],
                  }
                ],
              }} />
            </div>
          </div>
          <br /><br />
        </>
      )}
    </div>
  );
};
export default TableFinancial;
