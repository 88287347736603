import axios from 'axios';
import config from '../config';

const domain = config.api_domain.domain

const GetFinancialData = async (id, fuelID, loan, financing, interestEM, interestEM_fuel, installments) => {
    parseInt(fuelID)
    try {
        const url = (`${domain}/financial/${id}/${fuelID}/${loan}/${financing}/${interestEM}/${interestEM_fuel}/${installments}`)
        
        const res = await axios.get(url);
        return res.data.body;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export default GetFinancialData;