import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import UpdateService from '../../../../services/admin/updates/updateService';
import LoaderUtilsService2 from '../../../../services/admin/utils/loaderUtilsService2';
import { FaBackward } from 'react-icons/fa';
import { BiCheck } from 'react-icons/bi';
import Spinner from '../../../../components/spinner/Spinner';
import TooltipModal from '../../../../components/modals/ToolTipModal';
import ScraperTester from '../../../../services/admin/utils/ScraperTester';
import TooltipModalScraping from '../../../../components/modals/ToolTipModalScraping';

const UpdateScraping = ({ permission }) => {
    const params = useParams();
    const [datas, setDatas] = useState([]);
    const [formDataDolar, setFormDataDolar] = useState({});
    const [formDataInflation, setFormDataInflation] = useState({});
    const [loading, setLoading] = useState(true);
    const [showFormDolar, setShowFormDolar] = useState(false);
    const [showFormInflation, setShowFormInflation] = useState(false);
    const url = `admin/scrapings_by_country/${params.code}`;

    const [scrapDolar, setScrapDolar] = useState(null)
    const [scrapInflation, setScrapInflation] = useState(null)

    const getData = async () => {
        try {
            const scraping_data = await LoaderUtilsService2(url);
            setDatas(scraping_data)
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const getScrapTest = async (urlDolar, targetDolar, urlInflation, targetInflation) => {
        const dataDolar = {
            "url": urlDolar,
            "target": targetDolar
        };
    
        const dataInflation = {
            "url": urlInflation,
            "target": targetInflation
        };
        
        try {
            const dolarData = await ScraperTester(dataDolar);
            const inflationData = await ScraperTester(dataInflation);
    
            setScrapDolar(dolarData);
            console.log(scrapDolar)
            setScrapInflation(inflationData);
            console.log(scrapInflation)
        } catch (error) {
            console.error("Error fetching scrap data:", error);
            setScrapDolar("Sin respuesta válida para Dólar");
            setScrapInflation("Sin respuesta válida para Inflación");
        }
    };

    useEffect(() => {
        getData()
    }, [setDatas, url]);

    useEffect(() => {
        if (Object.keys(datas).length > 0) {
            setFormDataDolar({
                url: datas[0]['url'] || "",
                target: datas[0]['target'] || "",
            });

            setFormDataInflation({
                url: datas[1]['url'] || "",
                target: datas[1]['target'] || "",
            });

            getScrapTest(datas[0]['url'], datas[0]['target'], datas[1]['url'], datas[1]['target'])
        }
    }, [datas]);

    const handleChangeDolar = (e) => {
        const { name, value } = e.target;
        setFormDataDolar((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleChangeInflation = (e) => {
        const { name, value } = e.target;
        setFormDataInflation((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmitDolar = (e) => {
        e.preventDefault();
        const updatedDatasDolar = {
            ...formDataDolar,
            url: formDataDolar.url || "",
            target: formDataDolar.target || "",
        };
        setShowFormDolar(true);
        UpdateService('update_scrapings', datas[0]['id'], updatedDatasDolar)
            .then(() => {
                alert('Datos del dólar actualizados con éxito');
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleSubmitInflation = (e) => {
        e.preventDefault();
        const updatedDatasInflation = {
            ...formDataInflation,
            url: formDataInflation.url || "",
            target: formDataInflation.target || "",
        };
        setShowFormInflation(true);
        UpdateService('update_scrapings', datas[1]['id'], updatedDatasInflation)
            .then(() => {
                alert('Datos de inflación actualizados con éxito');
            })
            .catch((error) => {
                console.error(error);
            });
    };

    if (loading) {
        return <Spinner />;
    }

    if (!datas || datas.length <= 0 || datas.error || datas === null) {
        return <p>Sin país disponible</p>
    }

    return (
        permission ? (
            <>
                <div>
                    <Link to={`/admin/list_countries`}><FaBackward className="options-logo" />Volver a la lista</Link>
                    <br /><br />
                    <h3 className='subtitle mt-2'>Scrapings de {datas[0].country_name} <span><img src={datas[0].flag_image} alt={`Bandera de ${datas.country_name}`} style={{ maxWidth: '35px'}}/></span> </h3> 
                    <br /><br />
                        <>
                            <div className='container p-3 shadow-left' style={{backgroundColor: 'white'}}>
                                <h3 className='subtitle mt-2'>Dólar</h3><strong>Dato Extraido {scrapDolar ? JSON.stringify(scrapDolar) : "N/A"}</strong>
                                <div className="row">
                                    <div className="col-6">
                                        <label className='modal-lavel'>
                                            Página Web
                                            <input
                                                type="text"
                                                className='form-control'
                                                name="url"
                                                value={formDataDolar.url}
                                                onChange={handleChangeDolar}
                                            />
                                        </label>
                                    </div>
                                    <div className="col-6">
                                        <label className='modal-lavel'>
                                            Objetivo <TooltipModalScraping />
                                            <input
                                                type="text"
                                                className='form-control'
                                                name="target"
                                                value={formDataDolar.target}
                                                onChange={handleChangeDolar}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <br />
                                <button onClick={handleSubmitDolar}>Actualizar Dólar</button> 
                                {showFormDolar === true ? <BiCheck style={{ fontSize: '30px', color: 'green' }} /> : <></>}
                                <br /><br />
                                <h3 className='subtitle mt-2'>Inflación</h3> <strong>Dato Extraido {scrapInflation ? JSON.stringify(scrapInflation) : "N/A"}</strong>
                                <div className="row">
                                    <div className="col-6">
                                        <label className='modal-lavel'>
                                            Página Web
                                            <input
                                                type="text"
                                                className='form-control'
                                                name="url"
                                                value={formDataInflation.url}
                                                onChange={handleChangeInflation}
                                            />
                                        </label>
                                    </div>
                                    <div className="col-6">
                                        <label className='modal-lavel'>
                                            Objetivo <TooltipModalScraping />
                                            <input
                                                type="text"
                                                className='form-control'
                                                name="target"
                                                value={formDataInflation.target}
                                                onChange={handleChangeInflation}
                                            />
                                        </label>
                                    </div>
                                </div>

                                <br />
                                <button onClick={handleSubmitInflation}>Actualizar Inflación</button>
                                {showFormInflation === true ? <BiCheck style={{ fontSize: '30px', color: 'green' }} /> : <></>}
                            </div>
                        </>
                </div>
            </>
        ) : (
            <>Sin permisos de Super admin</>
        )
    );
};

export default UpdateScraping;
