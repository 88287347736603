import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const TooltipModalScraping = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="link" className='tooltip-btn' onClick={handleShow}>
        <i className="tooltip-question">i</i>
      </Button>

      <Modal className='modal-tooltip' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>¿Cómo obtener el objetivo del Scrapping?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
          1. Dirígete a la página web y busca el valor de interés que deseas vincular. Ejemplo: en el enlace https://www.dolar-colombia.com/ buscamos el valor del dólar en pesos colombianos.
          </p>
          <br />
          <p>
          2. Seleccionar el valor, clic derecho y clic en Inspeccionar .
          </p>
          <br />
          <p>
          3. Allí se abrirá la consola del navegador, señalando justamente el elemento HTML seleccionado. Dar clic derecho en el elemento, luego en Copiar y luego en Copiar selector . En el portapapeles quedó copiado el objetivo que debes ingresar en la Calculadora.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TooltipModalScraping;
